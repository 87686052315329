.App {
  text-align: center;
  max-height: 100vh;
  margin-top: 5vh;
  margin-bottom: 0;
}

.save-date{
  text-align: center;
  max-height: 100vh;
}

body{
  text-align: center;
  background-color: #323232;
  color: white;
}

.title-text{
  font-family: 'Sisterhood';
  font-size: 6rem;
  margin-top: 0;
  margin-bottom: 0;
}

.name-date{
  font-family: 'BodoniflfRoman';
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0;
}

/* vertical mobile display */
@media only screen and (max-width: 600px) and (orientation: portrait){
  header {
    font-size: 2rem;
    text-align: center;
    max-height: 0vh;
  }
  main {
    font-size: 1.2rem;
    text-align: center;
    max-height: 80vh;
  }
  footer {
    text-align: end;
    max-height: 20vh;
  }
  .show-counter{
    font-size: 0.85rem !important;
  }
}

/* horizontal mobile display */
@media only screen and (max-height: 600px) and (orientation: landscape){
  header {
    font-size: 2rem;
    text-align: center;
    max-height: 0vh;
  }
  main {
    font-size: 1.2rem;
    text-align: center;
    max-height: 80vh;
  }
  footer {
    text-align: end;
    max-height: 20vh;
  }
  .title-text{
    display: inline;
    margin-left: 10vh;
  }
  .App{
    margin-top: 2vh;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
